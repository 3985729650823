import moment from 'moment';

moment.updateLocale('en', {
    invalidDate: " "
});
moment.updateLocale('fr', {
    invalidDate: " "
});
moment.updateLocale('ar', {
    invalidDate: " "
});